import React, { useState, useEffect } from 'react';
import { LoadingScreen } from '../loading/LoadingScreen';
import { useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const TicketFailed: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { reasonCode = "79" } = useParams();
  const navigate = useNavigate();
  const [reason, setReason] = useState("取引に問題が発生しました。時間をおいて再度お試しください。");
  const reasonList: { [key: string]: string } = {
    "01": "カード認証に失敗しました。",
    "02": "不明な端末です。端末を変更して再度お試しください。",
    "03": "サポートされていない端末です。端末を変更して再度お試しください。",
    "04": "認証頻度の上限です。時間をおいて再度お試しください。",
    "05": "有効期限切れのカードです。",
    "06": "無効なカード番号です。",
    "07": "取引に問題が発生しました。時間をおいて再度お試しください。",
    "08": "取引に問題が発生しました。時間をおいて再度お試しください。",
    "09": "セキュリティ障害が発生しています。時間をおいて再度お試しください。",
    "10": "お支払いを完了できませんでした。ご利用のカード発行会社によって、セキュリティのため取引が停止された可能性があります。別のお支払い方法をお試しいただくか、カード発行会社へお問い合わせください。",
    "11": "お支払いを完了できませんでした。ご利用のカード発行会社によって、セキュリティのため取引が停止された可能性があります。別のお支払い方法をお試しいただくか、カード発行会社へお問い合わせください。",
    "12": "取引に問題が発生しました。時間をおいて再度お試しください。",
    "13": "取引に問題が発生しました。時間をおいて再度お試しください。",
    "14": "取引がACSでタイムアウトした",
    "15": "お支払いを完了できませんでした。ご利用のカード発行会社によって、セキュリティのため取引が停止された可能性があります。別のお支払い方法をお試しいただくか、カード発行会社へお問い合わせください。",
    "16": "お支払いを完了できませんでした。ご利用のカード発行会社によって、セキュリティのため取引が停止された可能性があります。別のお支払い方法をお試しいただくか、カード発行会社へお問い合わせください。",
    "17": "お支払いを完了できませんでした。ご利用のカード発行会社によって、セキュリティのため取引が停止された可能性があります。別のお支払い方法をお試しいただくか、カード発行会社へお問い合わせください。。",
    "18": "取引に問題が発生しました。時間をおいて再度お試しください。",
    "19": "認証の回数上限です。時間をおいて再度お試しください。",
    "20": "非決済取引はサポートされていません。",
    "21": "取引に問題が発生しました。時間をおいて再度お試しください。",
    "22": "認証の回数上限です。時間をおいて再度お試しください。",
    "26": "認証は試行されましたが、カード会員によって実行されませんでした。",
  };

  useEffect(() => {
    const referer = document.referrer; // リファラを取得
    const isFromApi = referer.startsWith(String(process.env.REACT_APP_GMO_API_BASE_URL).slice(0, -8));
    if (!isFromApi) {
      // リファラがGMOのドメインから来ていなければ、別ページにリダイレクト
      navigate('/');
    }
    if (reasonCode in reasonList) {
      setReason(reasonList[reasonCode])
    }
    setLoading(false);
  }, [navigate]);

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className="s-reset">
      <p className="topTitle">チケット購入に失敗しました。</p>
      <div className='mainSection'>
        <p className="fs-description">
          以下の理由のため、チケット購入に失敗しました。
        </p>
        <p className="fs-description my-3">
          エラーコード[{reasonCode}]<br />
          {reason}
        </p>
      </div>
    </div>
  );
};

export default TicketFailed; 